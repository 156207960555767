var ajaxUrl = 'https://rodoviariadebelohorizonte.com.br/wp-admin/admin-ajax.php';
var synth = window.speechSynthesis;

var to_speak = new SpeechSynthesisUtterance(
    document.querySelector('main').textContent
);

synth.cancel();

var buttonAudio = document.getElementById('audiodescricao');
var buttonAudioPause = 0;

buttonAudio.onclick = function (e) {
    
    e.preventDefault();
    buttonAudioPause++;

    if (buttonAudioPause == 1) {

        synth.speak(to_speak);
        buttonAudio.classList.add('active');
        buttonAudio.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-volume-up-fill" viewBox="0 0 16 16"><path d="M11.536 14.01A8.473 8.473 0 0 0 14.026 8a8.473 8.473 0 0 0-2.49-6.01l-.708.707A7.476 7.476 0 0 1 13.025 8c0 2.071-.84 3.946-2.197 5.303l.708.707z"/><path d="M10.121 12.596A6.48 6.48 0 0 0 12.025 8a6.48 6.48 0 0 0-1.904-4.596l-.707.707A5.483 5.483 0 0 1 11.025 8a5.483 5.483 0 0 1-1.61 3.89l.706.706z"/><path d="M8.707 11.182A4.486 4.486 0 0 0 10.025 8a4.486 4.486 0 0 0-1.318-3.182L8 5.525A3.489 3.489 0 0 1 9.025 8 3.49 3.49 0 0 1 8 10.475l.707.707zM6.717 3.55A.5.5 0 0 1 7 4v8a.5.5 0 0 1-.812.39L3.825 10.5H1.5A.5.5 0 0 1 1 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/></svg>';

    } else {

        synth.cancel();
        buttonAudioPause = 0;
        buttonAudio.classList.remove('active');
        buttonAudio.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" fill="currentColor" class="bi bi-volume-off-fill" viewBox="0 0 16 16"><path d="M10.717 3.55A.5.5 0 0 1 11 4v8a.5.5 0 0 1-.812.39L7.825 10.5H5.5A.5.5 0 0 1 5 10V6a.5.5 0 0 1 .5-.5h2.325l2.363-1.89a.5.5 0 0 1 .529-.06z"/></svg>';

    }

}

Fancybox.bind('[data-fancybox="gallery"]', {
    groupAll: true,
    Thumbs: {
        type: "classic",
    },
    Toolbar: {
        display: {
            left: ["infobar"],
            middle: [],
            right: ["download", "thumbs", "close"],
        }
    }
});


if (document.getElementsByClassName('alphabet-list').length) {

    var alphabetScroll = document.querySelector('.alphabet-list ul');

    var startY;
    var startX;
    var scrollLeft;
    var scrollTop;
    var isDown;

    alphabetScroll.addEventListener('mousedown', e => mouseIsDown(e));
    alphabetScroll.addEventListener('mouseup', e => mouseUp(e));
    alphabetScroll.addEventListener('mouseleave', e => mouseLeave(e));
    alphabetScroll.addEventListener('mousemove', e => mouseMove(e));    

    function mouseIsDown(e) {
        isDown = true;
        startY = e.pageY - alphabetScroll.offsetTop;
        startX = e.pageX - alphabetScroll.offsetLeft;
        scrollLeft = alphabetScroll.scrollLeft;
        scrollTop = alphabetScroll.scrollTop;
    }   

    function mouseUp(e) {
        isDown = false;
    }

    function mouseLeave(e) {
        isDown = false;
    }

    function mouseMove(e) {
        if (isDown) {
            e.preventDefault();
            //Move vertcally
            var y = e.pageY - alphabetScroll.offsetTop;
            var walkY = y - startY;
            alphabetScroll.scrollTop = scrollTop - walkY;

            //Move Horizontally
            var x = e.pageX - alphabetScroll.offsetLeft;
            var walkX = x - startX;
            alphabetScroll.scrollLeft = scrollLeft - walkX;

        }
    }

    alphabetScroll.addEventListener('touchstart', (ev)=>{
        for (let i = 0; i < ev.targetTouches.length; i++) {
            startX = ev.targetTouches[i].pageX - alphabetScroll.offsetLeft;
            scrollLeft = alphabetScroll.scrollLeft;
        }
    }, false);

    alphabetScroll.addEventListener('touchmove', (ev)=>{
        for (let i = 0; i < ev.targetTouches.length; i++) {
            var x = ev.targetTouches[i].pageX - alphabetScroll.offsetLeft;        
            var walkX = x - startX;
            alphabetScroll.scrollLeft = scrollLeft - walkX;
        }
    }, false);

}


jQuery(function ($) {
    $('#header-menu li.menu-item-has-children > a').on('click', function (e) {
        e.preventDefault();
        $(this).siblings('ul.sub-menu').slideToggle();
    });
    $('#mobile-menu li.menu-item-has-children > a').on('click', function (e) {
        e.preventDefault();
        $(this).siblings('ul.sub-menu').slideToggle();
    });
    if ($('.banner-slides').length) {
        $('.banner-slides').slick({
            infinite: true,
            slidesToShow: 1,
            slidesToScroll: 1,
            autoplay: true,
            autoplaySpeed: 5000,
            prevArrow: '<button type="button" class="slick-prev" aria-label="Anterior"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 33.726 50.724"><path d="M21.959.124.124,21.959a.42.42,0,0,0-.124.3v6.209a.417.417,0,0,0,.124.3L21.959,50.6a.42.42,0,0,0,.6,0L26.6,46.559a.42.42,0,0,0,0-.6L9.276,28.642l24.027,0a.424.424,0,0,0,.423-.423l0-5.72a.422.422,0,0,0-.422-.422H9.277L26.6,4.761a.42.42,0,0,0,0-.6L22.557.124a.42.42,0,0,0-.6,0Z" transform="translate(0 0)" fill="currentColor"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next" aria-label="Próximo"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 33.726 50.724"><path d="M11.767.124,33.6,21.96a.42.42,0,0,1,.124.3v6.209a.417.417,0,0,1-.124.3L11.767,50.6a.42.42,0,0,1-.6,0L7.13,46.559a.42.42,0,0,1,0-.6L24.451,28.642l-24.027,0A.424.424,0,0,1,0,28.223L0,22.5a.422.422,0,0,1,.422-.422H24.449L7.128,4.761a.42.42,0,0,1,0-.6L11.169.124a.42.42,0,0,1,.6,0Z" transform="translate(0 0)" fill="currentColor"/></svg></button>'
        });
    }
    if ($('#carousel-guide').length) {
        $('#carousel-guide').slick({
            infinite: true,
            slidesToShow: 5,
            slidesToScroll: 5,
            responsive: [
                {
                    breakpoint: 1300,
                    settings: {
                        slidesToShow: 4,
                        slidesToScroll: 4,
                    }
                },
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: 3,
                        slidesToScroll: 3,
                    }
                },
                {
                    breakpoint: 900,
                    settings: {
                        slidesToShow: 2,
                        slidesToScroll: 2,
                    }
                },
                {
                    breakpoint: 500,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1,
                    }
                }
            ],
            prevArrow: '<button type="button" class="slick-prev" aria-label="Anterior"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 33.726 50.724"><path d="M21.959.124.124,21.959a.42.42,0,0,0-.124.3v6.209a.417.417,0,0,0,.124.3L21.959,50.6a.42.42,0,0,0,.6,0L26.6,46.559a.42.42,0,0,0,0-.6L9.276,28.642l24.027,0a.424.424,0,0,0,.423-.423l0-5.72a.422.422,0,0,0-.422-.422H9.277L26.6,4.761a.42.42,0,0,0,0-.6L22.557.124a.42.42,0,0,0-.6,0Z" transform="translate(0 0)" fill="currentColor"/></svg></button>',
            nextArrow: '<button type="button" class="slick-next" aria-label="Próximo"><svg xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 33.726 50.724"><path d="M11.767.124,33.6,21.96a.42.42,0,0,1,.124.3v6.209a.417.417,0,0,1-.124.3L11.767,50.6a.42.42,0,0,1-.6,0L7.13,46.559a.42.42,0,0,1,0-.6L24.451,28.642l-24.027,0A.424.424,0,0,1,0,28.223L0,22.5a.422.422,0,0,1,.422-.422H24.449L7.128,4.761a.42.42,0,0,1,0-.6L11.169.124a.42.42,0,0,1,.6,0Z" transform="translate(0 0)" fill="currentColor"/></svg></button>'
        });
    }
    if ($('.banner-home').length) {
        $('.banner-home .nextend-arrow-previous').html('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 33.726 50.724"><path d="M21.959.124.124,21.959a.42.42,0,0,0-.124.3v6.209a.417.417,0,0,0,.124.3L21.959,50.6a.42.42,0,0,0,.6,0L26.6,46.559a.42.42,0,0,0,0-.6L9.276,28.642l24.027,0a.424.424,0,0,0,.423-.423l0-5.72a.422.422,0,0,0-.422-.422H9.277L26.6,4.761a.42.42,0,0,0,0-.6L22.557.124a.42.42,0,0,0-.6,0Z" transform="translate(0 0)" fill="currentColor"/></svg>');
        $('.banner-home .nextend-arrow-next').html('<svg xmlns="http://www.w3.org/2000/svg" width="30" height="50" viewBox="0 0 33.726 50.724"><path d="M11.767.124,33.6,21.96a.42.42,0,0,1,.124.3v6.209a.417.417,0,0,1-.124.3L11.767,50.6a.42.42,0,0,1-.6,0L7.13,46.559a.42.42,0,0,1,0-.6L24.451,28.642l-24.027,0A.424.424,0,0,1,0,28.223L0,22.5a.422.422,0,0,1,.422-.422H24.449L7.128,4.761a.42.42,0,0,1,0-.6L11.169.124a.42.42,0,0,1,.6,0Z" transform="translate(0 0)" fill="currentColor"/></svg>');
    }

    $('.select2Destino').select2({
        placeholder: "Destino",
        language: "pt-BR",
        ajax: {
            url: 'https://api.clickbus.com/api/v3/places',
            dataType: 'json',
            data: function(params) {
                var search;
                if(params.term){
                    search = params.term;
                }else{
                    search = '';
                }
                var query = {
                    clientId: 11160,
                    limit: 5,
                    name: search,
                    fields: 'slug,name'
                }

                // Query parameters will be ?search=[term]&type=public
                return query;
            },
            processResults: function(data) {
                // Transforms the top-level key of the response object from 'items' to 'results'
                return {
                    results: $.map(data, function(obj) {
                        return {
                            id: obj.slug,
                            text: obj.name
                        };
                    })
                };
            }
        }
    });
    $('.select2Origem').select2({
        placeholder: "Origem",
        language: "pt-BR",
        ajax: {
            url: 'https://api.clickbus.com/api/v3/places',
            dataType: 'json',
            data: function(params) {
                var search;
                if(params.term){
                    search = params.term;
                }else{
                    search = '';
                }
                var query = {
                    clientId: 3,
                    limit: 9,
                    name: search,
                    fields: 'slug,name'
                }

                // Query parameters will be ?search=[term]&type=public
                return query;
            },
            processResults: function(data) {
                // Transforms the top-level key of the response object from 'items' to 'results'
                return {
                    results: $.map(data, function(obj) {
                        return {
                            id: obj.slug,
                            text: obj.name
                        };
                    })
                };
            }
        }
    });

    $('#submit-search-times').on('click', function (e) {
        e.preventDefault();
        var inputName = $('#destino-search-times option:selected').val();

        var dataPost = {
            'action': 'clickbus_search',
            'destino': inputName
        };

        if (inputName != null && inputName != '') {
            $.ajax({
                method: "POST",
                url: ajaxUrl,
                cache: false,
                data: dataPost,
                success: function (jsonResult) {
                    window.open(jsonResult, "_blank");
                }
            });
        } else {
            alert('Selecione um destino.');
        }
    });

    $('#submit-search-ticket').on('click', function (e) {
        e.preventDefault();

        var inputDestino = $('#input-destino').val();
        var inputOrigem = $('#input-origem').val();
        var dataIda = $('#input-ida').val();
        var dataVolta = $('#input-volta').val();

        var dataPost = {
            'action': 'clickbus_search',
            'origem': inputOrigem,
            'destino': inputDestino,
            'ida': dataIda,
            'volta': dataVolta
        };

        if ((inputDestino != null && inputDestino != '') || (inputOrigem != null && inputOrigem != '')) {
            $.ajax({
                method: "POST",
                url: ajaxUrl,
                cache: false,
                data: dataPost,
                success: function (jsonResult) {
                    window.open(jsonResult, "_blank");
                }
            });
        } else {
            alert('Selecione um destino.');
        }
    });

    $('#input-ida').on('change', function(){
        $('#input-volta').attr('min', $(this).val());
    });
});